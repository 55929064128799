<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <!-- Form -->
    <div
      class="tabs-container"
    >
      <b-tabs
        v-if="userData"
        pills
        lazy
        class="tab-action-btn-fill-container"
        justified
      >

        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Account</span>
          </template>

          <hr>
          <div class="mt-2">
            <b-media class="mb-2">
              <!-- User Image -->
              <template #aside>
                <b-img
                  ref="profileImage"
                  :src="
                    $isNotEmpty(userData.profile_image_url)
                      ? `${userData.profile_image_url}`
                      : require('@/assets/images/placeholder/no-image.jpg')
                  "
                  width="120px"
                  height="120px"
                />
              </template>

              <h4 class="mb-1">
                {{ userData.fullname }}
              </h4>

              <!-- User Image Actions -->
              <div class="d-flex flex-wrap">

                <div
                  style="width: 200px; height: 66px;"
                >
                  <file-pond
                    ref="pond-user"
                    name="profile-pic-upload"
                    label-idle="Upload a new image."
                    accepted-file-types="image/jpeg, image/png"
                    :server="profileServer"
                    :files="profileFiles"
                    credits="false"
                    @init="handleProfileImagePondInit"
                  />
                </div>
                <b-button
                  v-if="userData.profile_image"
                  variant="outline-danger"
                  class="ml-1"
                  @click="removeProfileImage"
                >
                  <span class="d-none d-sm-inline">Remove Image</span>
                  <feather-icon
                    icon="TrashIcon"
                    class="d-inline d-sm-none"
                  />
                </b-button>

              </div>
            </b-media>

            <!-- Fields -->
            <b-row>
              <!-- Field: fullname -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Full Name"
                  label-for="fullname"
                >
                  <b-form-input
                    id="fullname"
                    v-model="userData.fullname"
                    :state="$checkFullName(userData.fullname)['state']"
                  />
                  <b-form-invalid-feedback
                    class="ml-1"
                  >
                    {{ $checkFullName(userData.fullname)['message'] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!-- Field: email -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="userData.email_address"
                    disabled
                    placeholder="Email"
                    :state="$checkEmail(userData.email_address).state"
                  />
                  <b-form-invalid-feedback
                    class="ml-1"
                    tooltip
                  >
                    {{ $checkEmail(userData.email_address).message }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <!-- Field: phone_number -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Phone Number"
                  label-for="phone_number"
                >
                  <b-form-input
                    id="phone_number"
                    v-model="userData.phone_number"
                    disabled
                    placeholder="Phone Number"
                    type="number"
                    :formatter="$limitPhoneNumber"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: company_name -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Company Name"
                  label-for="company_name"
                >
                  <b-form-input
                    id="company_name"
                    v-model="userData.company_name"
                    placeholder="Company Name"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- Field: team -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Team / Department"
                  label-for="team"
                >
                  <b-form-input
                    id="team"
                    v-model="userData.team"
                    placeholder="Team Name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <!-- Tab: Permissions -->
        <b-tab
          v-if="$store.state.auth.ActiveUser.email_address !== userData.email_address"
        >
          <template #title>
            <feather-icon
              icon="KeyIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Permissions</span>
          </template>

          <hr>

          <b-row>
            <b-col
              cols="12"
            >
              <!-- Field: role -->
              <b-form-group
                label="User Role"
                label-for="role"
                class="px-2"
              >
                <v-select
                  id="role"
                  ref="role"
                  v-model="selected_role_id"
                  :placeholder="
                    userData.role_name
                      ? userData.role_name
                      : 'Pick a role or assign permissions to this user'
                  "
                  class="selectExample w-full"
                  :options="roleOptions"
                  :reduce="role => role.value"
                  label="text"
                  @input="repopulatePermissions"
                />
              </b-form-group>
            </b-col>

            <!-- PermissionsCard -->
            <b-col cols="12">
              <permissions-card
                v-if="renderPermissionsCard"
                :user-permissions="userData.permissions"
                @change="handlePermissions"
              />
            </b-col>
          </b-row>
        </b-tab>

        <!-- Tab: Security -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Security</span>
          </template>

          <hr>

          <div
            class="my-1 ml-1"
          >
            <b-form-checkbox
              v-model="send_through_system"
              class="w-100 ml-1"
            >Send login details through HeadHunters HQ system</b-form-checkbox>
          </div>
          <!-- Generate Pass -->
          <b-row class="mx-1">

            <b-col
              cols="12"
              class="mb-1"
              lg="6"
              xl="3"
            >
              <b-button
                variant="warning"
                class="w-100"
                @click="generatePass"
              >
                Generate new password
              </b-button>
            </b-col>

            <b-col
              v-if="!send_through_system && show_pass"
              cols="12"
              lg="6"
              class="mb-1"
            >
              <b-form-input
                v-model="generated_pass"
                class=""
                @click="copyTextToClipboard"
              />
              <b-alert
                class="mt-1"
                variant="warning"
                :show="!send_through_system && show_pass"
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span class="ml-25">Please copy the new generated password because it wont be available
                    once you close this screen.</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

        </b-tab>
      </b-tabs>
    </div>

    <!-- Action Buttons -->
    <div class="d-flex flex-row-reverse my-2">
      <b-button
        class="mx-1 view-profile"
        variant="primary"
        @click="viewUser"
      >View Profile</b-button>

      <ButtonSpinner
        :is-loading="isLoading"
        class="mx-1 click-save"
      >
        <b-button
          variant="warning"
          class="mx-1 click-save"
          @click="saveUserProfile"
        >Save User Profile</b-button>
      </ButtonSpinner>
    </div>
  </component>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BImg,
  BLink,
  BMedia,
  BRow,
  BTab,
  BTabs,
  BTable,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import vueFilePond, { setOptions } from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

// Import FilePond plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import { pick } from 'lodash'
import { singleUpload, deleteObjectByKey } from '@/components/awsS3'
import UserManagerEditTabAccount from './UserMangerEditTabAccount.vue'
import UserManagerEditTabInformation from './UserManagerEditTabInformation.vue'
import UserManagerEditTabSocial from './UserManagerEditTabSocial.vue'
import PermissionsCard from '../components/PermissionsCard.vue'

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,

)

export default {
  components: {
    FilePond,
    BAlert,
    BAvatar,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
    BImg,
    BLink,
    BMedia,
    BRow,
    BTab,
    BTabs,
    BTable,
    VSelect,

    PermissionsCard,

    UserManagerEditTabAccount,
    UserManagerEditTabInformation,
    UserManagerEditTabSocial,
  },

  data() {
    return {
      isLoading: false,
      renderPermissionsCard: true,
      selected_role_id: null,
      roleOptions: [
        {
          text: 'custom',
          value: 'custom',
        },
      ],
      show_pass: false,
      generated_pass: null,
      send_through_system: false,
      profileServer: {

      },
      profileFiles: null,
    }
  },
  setup() {
    const userData = ref(null)

    const genderOptions = [
      {
        text: 'Male',
        value: 'Male',
      },
      {
        text: 'Female',
        value: 'Female',
      },
    ]

    const employmentStatusOptions = [
      {
        text: 'Unemployed for less than 6 months',
        value: 'Unemployed for less than 6 months',
      },
      {
        text: 'Unemployed for more than 6 months',
        value: 'Unemployed for more than 6 months',
      },
      {
        text: 'Employed',
        value: 'Employed',
      },
    ]

    const citizenshipOptions = [
      {
        text: 'Singaporean',
        value: 'Singaporean',
      },
      {
        text: 'Singaporean PR',
        value: 'Singaporean PR',
      },
      {
        text: 'Others',
        value: 'Others',
      },
    ]

    const availabilityPeriodOptions = [
      {
        text: 'Days',
        value: 'Days',
      },
      {
        text: 'Weeks',
        value: 'Weeks',
      },
      {
        text: 'Months',
        value: 'Months',
      },
    ]

    const prefLocationOptions = [
      {
        text: 'North',
        value: 'North',
      },
      {
        text: 'South',
        value: 'South',
      },
      {
        text: 'Central',
        value: 'Central',
      },
      {
        text: 'East',
        value: 'East',
      },
      {
        text: 'West',
        value: 'West',
      },
    ]

    const perm_rights = {}

    const permissionsData = [
      {
        module: 'Analytics',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Data',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'User',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Audit',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Events',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Agencies Directory',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
      {
        module: 'Finance',
        read: true,
        write: true,
        create: true,
        delete: true,
      },
    ]

    return {
      // Tab: account
      userData,
      genderOptions,
      employmentStatusOptions,
      citizenshipOptions,
      availabilityPeriodOptions,
      prefLocationOptions,

      // Tab: permissions
      permissionsData,

      perm_rights,

    }
  },
  created() {
    this.get_user()
    this.get_roles()
  },
  methods: {

    handleProfileImagePondInit() {
      const { id } = this.$route.params
      const outerThis = this
      const imgRef = this.$refs.profileImage
      this.$refs['pond-user']._pond.setOptions({
        server: {
          process(fieldName, file, metadata, load, error, progress, abort) {
            const extension = file.name.split('.').pop()

            singleUpload(file, 'user-profile-images', `${id}.${extension}`, load, error, progress, abort, imgRef, outerThis, true)
          },
        },
      })

      // FilePond instance methods are available on `this.$refs.pond`
    },

    get_user() {
      const { id } = this.$route.params

      this.$http
        .get(`/api/users/${id}`)
        .then(response => {
          this.userData = response.data.user

          const { permissions } = this.userData
          if (this.$isEmpty(this.userData.role_id)) {
            this.$set(this.selected_role_id = 'custom')
          }
          this.$set(this.userData, 'permissions', [])
          this.$set(this.userData, 'permissions', permissions)

          // set default value if availability is empty to prevent error
          if (!this.userData.availability) {
            this.userData.availability = {
              number: 0,
              period: 'Not Set',
            }
          }
        })
        .catch(() => {
          //
        })
    },
    get_roles() {
      this.$http
        .get('/api/roles')
        .then(response => {
          const { roles } = response.data

          roles.forEach(role => {
            this.roleOptions.push({
              text: role.name,
              value: role.id,
            })
          })
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },

    isFormValid(formData) {
      let state = true
      const messages = []

      const checkedFullName = this.$checkFullName(formData.fullname)
      if (!checkedFullName.state) {
        state = checkedFullName.state
        messages.push(checkedFullName.message)
      }

      const checkedPhoneNumber = this.$checkPhoneNumber(formData.phone_number)
      if (!checkedPhoneNumber.state) {
        state = checkedPhoneNumber.state
        messages.push(checkedPhoneNumber.message)
      }

      const checkedTeam = this.$checkTeam(formData.team)
      if (!checkedTeam.state) {
        state = checkedTeam.state
        messages.push(checkedTeam.message)
      }

      return { state, messages }
    },
    saveUserProfile() {
      let proceed = true

      //* Validation
      const isValid = this.isFormValid(this.userData)
      if (!isValid.state) {
        proceed = false
        this.$toastDanger('Invalid Value', isValid.messages)
      }

      //* Submit to backend
      if (proceed) {
        const { token, account_type } = this.$store.state.auth.ActiveUser
        const { id } = this.$route.params
        const form_data = pick(this.userData, [
          'fullname',
          'team',
          'permissions',
          'role_id',
          'profile_image',
        ])
        form_data.id = id
        form_data.account_type = account_type
        if (this.selected_role_id && this.selected_role_id.toLowerCase() !== 'custom') {
          form_data.role_id = this.selected_role_id
        }

        if (this.generated_pass != null) {
          form_data.password = this.generated_pass
          form_data.send_through_system = this.send_through_system
        } else {
          form_data.password = null
          form_data.send_through_system = this.send_through_system
        }

        this.isLoading = true
        this.$http
          .put('/api/users', {
            new_user_info: form_data,
          })
          .then(response => {
            const { success, message } = response.data
            if (success) {
              this.$toastSuccess('Profile Update', "The user's profile has been updated successfully!")
              this.generated_pass = null
              this.viewUser()
            } else {
              this.$toastDanger('Operation Status', message)
            }

            this.isLoading = false
          })
          .catch(error => {
            this.$handleErrorResponse(error)
            this.isLoading = false
          })
      }
    },

    repopulatePermissions() {
      this.$http
        .get(`/api/roles/${this.selected_role_id}`)
        .then(response => {
          const role = response.data
          this.userData.role = role.name
          this.userData.role_id = role.id

          this.$delete(this.userData, 'permissions')
          this.$set(this.userData, 'permissions', role.permissions)

          this.renderPermissionsCard = false
          setTimeout(() => {
            this.renderPermissionsCard = true
          }, 100)
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },

    viewUser() {
      const { id } = this.$route.params
      this.$router.push({ name: 'user-manager-view', params: { id } })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {}

        const outerThis = this
        const img = new Image()
        img.src = window.URL.createObjectURL(input.target.files[0])
        img.onload = () => {
          if (img.height !== 400 || img.width !== 400) {
            outerThis.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Image size error',
                  icon: 'AlertCircleIcon',
                  text: 'The accepted width and height of the image is 400px and 400px respectively.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
            return false
          }

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Upload Status',
                icon: 'AlertCircleIcon',
                text: 'Uploading image in progress.',
                variant: 'warning',
                progress: 0,
                progressId: 'uploadImage',
              },
            },
            { timeout: 99999999 },
          )

          const fd = new FormData()
          fd.append('file', input.target.files[0])
          fd.append('target_email', this.userData.email_address)
          const url = '/api/user-profile-image'

          this.$refs.uploadImgInput.value = null
          this.$http
            .post(url, fd, {
              onUploadProgress(progressEvent) {
                const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                const parent = document.querySelector('#uploadImage')
                parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
              },
            })
            .then(response => {
              if (response.data.success) {
                this.$refs.profileImage.src = response.data.image

                // if (this.profileType) {
                //   this.$refs.profileImage.src = response.data.image
                //   this.saveProfile()
                // } else {
                //   this.accountInfo.avatar = response.data.image
                //   this.$refs.profileImage.src = response.data.image
                // }
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Profile Update',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'success',
                    },
                  },

                )
              } else {
                this.$toast(
                  {
                    component: this.$toastContent,
                    props: {
                      title: 'Profile Update',
                      icon: 'AlertCircleIcon',
                      text: response.data.message,
                      variant: 'danger',
                    },
                  },
                )
              }

              this.isLoading = false
            })
            .catch(() => {})

          const { token } = this.$store.state.auth.ActiveUser
          this.$http.defaults.headers.common.Authorization = `Token ${token}`
          reader.readAsDataURL(input.target.files[0])
        }
      }
    },
    removeProfileImage() {
      const outerThis = this
      deleteObjectByKey(this.userData.profile_image, outerThis, true)
    },

    // permissions
    handlePermissions(permissions) {
      this.userData.permissions = permissions
      this.selected_role_id = 'custom'
      this.$set(this.userData, 'role', 'custom')
      this.$set(this.userData, 'role_id', null)
    },

    // password
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16))
    },
    generatePass() {
      // const uniq = this.uuidv4()
      this.generated_pass = this.$passwordGenerator(16)
      this.show_pass = true
      this.$toastSuccess('Password Notification', 'Password generated successfully.')
    },
    copyTextToClipboard() {
      const text = this.generated_pass
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
      this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Copy Operation',
            icon: 'CheckCircleIcon',
            text: 'Text copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
